<template>
    <v-container fluid>
    <v-row>
      <v-col class="pt-3 pb-2">
        <h1>Update Daily Return Sheet for {{ drs.job_no }}</h1>
        <p class="pt-2">
          <v-btn small @click="showChangeJobNo = true">Change Job Number</v-btn>
        </p>
      </v-col>
    </v-row>
    <v-row v-if="drs.quotation">
      <v-col class="col-3 pt-3 pb-2">
        <v-text-field v-if="drs.quotation.customer" dense label="Customer" v-model="drs.quotation.customer.name" disabled></v-text-field>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="col-3 pt-3 pb-2">
        Internal / Yard WorkDRS
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <label>Date for the DRS</label>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="drs.work_date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-select
          label="Department"
          v-model="drs.department_id"
          :items="departments"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-select
          label="Surface Type"
          v-model="drs.surface_type_id"
          :items="surfaceTypes"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-select
          label="Surface Conditions"
          v-model="drs.surface_condition_id"
          :items="surfaceConditions"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-select
          label="Weather Conditions"
          v-model="drs.weather_id"
          :items="weatherConditions"></v-select>
      </v-col>
    </v-row>
    <v-row v-if="drs.quotation">
      <v-col class="col-6 pt-3 pb-2">
        <v-text-field dense disabled v-model="drs.quotation.site_location" label="Site"></v-text-field>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="col-6 pt-3 pb-2">
        <p>Travel / Yard Work</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-1">
        <v-select
          label="Chargehand"
          v-model="drs.daily_return_sheets_workers[0].crew_member.id"
          :items="crewMembers"
          item-value="id"
          item-text="name"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pb-8">
        <v-btn @click="addWorker" x-small>Add Worker</v-btn>
      </v-col>
    </v-row>
    <div v-for="(worker, index) in drs.daily_return_sheets_workers" :key="`w_${index}`">
      <v-row v-if="index > 0">
        <v-col class="col-3 pt-3 pb-6">
          <v-select
            :label="`Worker ${index}`"
            v-model="worker.crew_member.id"
            :items="crewMembers"
            item-value="id"
            item-text="name"></v-select>
            <v-btn @click="removeWorker(index)" x-small>Remove worker</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-text-field
          v-model="drs.vehicle"
          label="Vehicle Reg"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-text-field
          v-model="drs.vehicle_two"
          label="2nd Vehicle Reg"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-text-field
          v-model="drs.vehicle_three"
          label="3rd Vehicle Reg"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-text-field
          v-model="drs.mileage"
          label="Mileage"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-text-field
          v-model="drs.drawing_number"
          label="Drawing No"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-text-field
          v-model="drs.revision_number"
          label="Revision No"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-select
          label="Type of Works"
          v-model="drs.type_code"
          :items="jobTypes"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-9 pt-3 pb-2">
        <p>
          Please use the selection tickboxes to detail the material/job type. You can select
          more than one item.
        </p>
        <v-checkbox dense :ripple="false" label="Overbanding Grout" v-model="drs.overbanding_grout"></v-checkbox>
        <v-checkbox dense :ripple="false" label="Beads" v-model="drs.beads"></v-checkbox>
        <v-checkbox dense :ripple="false" label="Epoxy" v-model="drs.epoxy"></v-checkbox>
        <v-checkbox dense :ripple="false" label="Thermoplastic" v-model="drs.thermoplastic"></v-checkbox>
        <v-checkbox dense :ripple="false" label="Paint" v-model="drs.paint"></v-checkbox>
        <v-checkbox dense :ripple="false" label="MMA" v-model="drs.mma"></v-checkbox>
        <v-checkbox dense :ripple="false" label="Anti Skid" v-model="drs.anti_skid"></v-checkbox>
        <v-checkbox dense :ripple="false" label="Tack Coat" v-model="drs.tack_coat"></v-checkbox>
        <v-checkbox dense :ripple="false" label="Remedial Work" v-model="drs.remedial_work"></v-checkbox>
        <v-checkbox dense :ripple="false" label="Sealer" v-model="drs.sealer"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-3 pb-2">
        <hr />
        <p class="pt-4">Health and Safety</p>
        <v-checkbox
          dense
          :ripple="false"
          label="Tick to confirm all PPE was available and in good condition"
          v-model="drs.ppe_good_condition"></v-checkbox>
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label>Bill of Quantities</label>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="newSectionHeading.length === 0" small @click="addSectionHeading = true">
          Add a section heading
        </v-btn>
        &nbsp;
        <v-btn
          v-if="sectionList.length > 0"
          @click="editSectionHeadings = true"
          small>
          Edit section headings
        </v-btn>
        <div v-if="newSectionHeading.length > 0">
          <h4 class="mb-2">Current section heading: {{ newSectionHeading }}</h4>
          <v-btn @click="addNewSectionHeading" small>Add a new section heading</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Add misc bill of quantity</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Rate</th>
              <th>Quantity</th>
              <th>Value</th>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-text-field
                  label="Description"
                  v-model="newBonus.description"></v-text-field>
              </td>
              <td>
                <v-text-field
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="newBonus.rate"
                  prefix="£"></v-text-field>
              </td>
              <td>
                <v-text-field
                  type="number"
                  min="0"
                  step="1"
                  v-model="newBonus.quantity"></v-text-field>
              </td>
              <td>{{ (newBonus.quantity * newBonus.rate) | currency }}</td>
              <td>
                <v-btn x-small @click="saveNewBonus">Save</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Description</th>
              <th>Rate</th>
              <th>Quantity</th>
              <th>Value</th>
              <th>Heading</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(boq, index) in drs.daily_return_sheets_bonus_payments" :key="`b_${index}`">
              <td>{{ boq.bonus_rate.description }}</td>
              <td>
                <v-text-field
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="boq.rate"
                  prefix="£"></v-text-field>
              </td>
              <td>
                <v-text-field
                  type="number"
                  min="0"
                  step="1"
                  v-model="boq.quantity"></v-text-field>
              </td>
              <td>{{ (boq.quantity * boq.rate) | currency }}</td>
              <td>
                <span v-if="boq.section">
                  <select :data-boq="index" @change="updateBoqSectionHeading">
                    <option
                      :value="heading.id"
                      v-for="(heading, index) in sectionList"
                      :key="`sl_${index}`"
                      :selected="heading.id === boq.section.id">
                      {{ heading.description }}
                    </option>
                  </select>
                </span>
              </td>
              <td>
                <v-btn x-small @click="updateLine(index)">Update</v-btn>
                &nbsp;
                <v-btn x-small @click="removeLine(index)">Remove</v-btn>
              </td>
            </tr>
            <tr v-for="(bonus, bindex) in bonuses" :key="bindex">
              <td>{{ bonus.description }}</td>
              <td>
                <v-text-field
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="bonus.rate"
                  prefix="£"></v-text-field>
              </td>
              <td>
                <v-text-field
                  type="number"
                  min="0"
                  step="1"
                  v-model="bonus.quantity"></v-text-field>
              </td>
              <td>{{ (bonus.quantity * bonus.rate) | currency }}</td>
              <td>&nbsp;</td>
              <td>
                <v-btn x-small @click="useBonus(bindex)">Save</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Misc BOQs from PAL</p>
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-text-field
                  v-model="drs.drs_misc_boq_1_description"></v-text-field>
              </td>
              <td>
                <v-text-field
                  type="number"
                  min="0"
                  step="1"
                  v-model="drs.drs_misc_boq_1_qty"></v-text-field>
                </td>
            </tr>
            <tr>
              <td>
                <v-text-field
                  v-model="drs.drs_misc_boq_2_description"></v-text-field>
              </td>
              <td>
                <v-text-field
                  type="number"
                  min="0"
                  step="1"
                  v-model="drs.drs_misc_boq_2_qty"></v-text-field>
                </td>
            </tr>
            <tr>
              <td>
                <v-text-field
                  v-model="drs.drs_misc_boq_3_description"></v-text-field>
              </td>
              <td>
                <v-text-field
                  type="number"
                  min="0"
                  step="1"
                  v-model="drs.drs_misc_boq_3_qty"></v-text-field>
                </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-8">
        <label>Misc Bonuses</label>
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th>Amount</th>
              <th>Total</th>
              <th>&nbsp;</th>
            </tr>
            <tr v-for="(sb, sbindex) in drs.daily_return_sheet_special_bonnuses" :key="(sb.id)">
              <td>{{ sb.description }}</td>
              <td>{{ sb.quantity }}</td>
              <td>&pound;{{ sb.bonus_rate }}</td>
              <td>&pound;{{ sb.total }}</td>
              <td>
                <v-btn @click="deleteSpecialBonus(sbindex)" x-small>Delete</v-btn>
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field v-model="newSpecialBonus.description"></v-text-field>
              </td>
              <td>
                <v-text-field
                  min="0"
                  step="1"
                  type="number"
                  v-model.number="newSpecialBonus.quantity"></v-text-field>
              </td>
              <td>
                <v-text-field
                  min="0"
                  prefix="£"
                  step="0.01"
                  type="number"
                  v-model.number="newSpecialBonus.amount"></v-text-field>
              </td>
              <td>
                <v-text-field
                  disabled
                  min="0"
                  prefix="£"
                  step="0.01"
                  type="number"
                  v-model.number="newSpecialBonus.total"></v-text-field>
              </td>
              <td>
                <v-btn @click="saveNewSpecialBonus" x-small>Save</v-btn>
              </td>
            </tr>
          </thead>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-6">
        <label>Bonuses</label>
        <v-simple-table dense>
          <thead>
            <tr>
              <td>
                <v-text-field
                  label="Total bonus"
                  v-model="totalBonus"
                  type="number"
                  step="0.01"
                  prefix="£"></v-text-field>
              </td>
            </tr>
            <tr v-for="(crew) in drs.daily_return_sheets_workers" :key="(crew.id)">
              <td>
                Bonus for {{ crew.crew_member.name }}:
                <span v-if="!crew.working_away && !crew.working_nights">
                  {{ individualBonus | currency }}  
                </span>
                <span v-if="crew.working_away && !crew.working_nights">
                  {{ ((individualBonus / 2) + individualBonus) | currency }} (+50% for working away). 
                </span>
                <span v-if="!crew.working_away && crew.working_nights">
                  {{ ((individualBonus * 0.33) + individualBonus) | currency }}  (+33% for working nights). 
                </span>
                <span v-if="crew.working_away && crew.working_nights">
                  {{ (((individualBonus * 0.33) + (individualBonus / 2)) + individualBonus) | currency }}  (+50% for working away and +33% for working nights).
                </span>
              </td>
            </tr>
          </thead>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="drs.minimum_bonus">
      <v-col>
        <p v-if="(totalBonus > 10)">This job requires a minimum bonus, but the calculated bonus is over £10 in value so it has been over-ridden.</p>
        <p v-else>
          <v-select
            label="A minimum bonus of £10 will be applied. Do you want to remove this minimum bonus?"
            :items="boolean"
            v-model="drs.minimum_bonus"
          ></v-select>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label>Materials Used</label>
        <v-simple-table class="mt-2" dense>
          <thead>
            <tr>
              <th>Material Type</th>
              <th>Colour</th>
              <th>Batch No</th>
              <th>No of Bags</th>
              <th>Material Temp</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(material, index) in drs.daily_returns_sheets_material_usages"
              :key="`mt_${index}`">
              <td><v-text-field v-model="material.type"></v-text-field></td>
              <td><v-text-field v-model="material.colour"></v-text-field></td>
              <td><v-text-field v-model="material.batch_number"></v-text-field></td>
              <td><v-text-field v-model="material.no_of_bags"></v-text-field></td>
              <td><v-text-field v-model="material.material_temperature"></v-text-field></td>
              <td>
                <v-btn
                  x-small
                  @click="saveMaterialChanges(index)">Save</v-btn>
                &nbsp;
                <v-btn
                  x-small
                  @click="removeMaterial(index)">Remove</v-btn>
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field v-model="newMaterial.type"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="newMaterial.colour"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="newMaterial.batch_number"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="newMaterial.no_of_bags"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="newMaterial.material_temperature"></v-text-field>
              </td>
              <td>
                <v-btn @click="addMaterial" x-small>Save</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr />
      </v-col>
    </v-row>
    <v-row v-if="drs.daily_return_sheets_images.length > 0">
      <v-col>
        <hr />
        <p class="mt-4">Images from the DRS:</p>
        <v-simple-table>
          <tbody>
            <tr>
              <td>
                <img
                  class="small-image mr-5"
                  v-for="(image, index) in drs.daily_return_sheets_images"
                  :src="image"
                  :key="index" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2 pt-0 pb-2 text-right">
        <v-checkbox
          :ripple="false"
          label="Is all the available work complete / no return visit required?"
          v-model="drs.work_complete"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2 pt-0 pb-2 text-right">
        <v-textarea
          label="If not complete, why?"
          v-model="drs.incomplete_work_reason"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2 pt-0 pb-2 text-right">
        <v-checkbox
          :ripple="false"
          label="Is this a phased visit?"
          v-model="drs.phased_visit"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2 pt-0 pb-2 text-right">
        <v-checkbox
          :ripple="false"
          label="Total Job Complete?"
          v-model="drs.job_complete"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2 pt-0 pb-2 text-right">
        <v-checkbox
          :ripple="false"
          label="Are there any Disclaimers, Absolvency or extras to record?"
          v-model="drs.disclaimers_to_record"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-textarea v-model="drs.notes" label="Notes"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-text-field
          v-model="drs.signed_by" 
          label="Enter the name of the customer signing off"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-textarea v-model="drs.customer_comment" label="Customer comments"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-text-field v-model="drs.sign_off_team_member_name" label="Name of team member signing off"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pb-10">
      <v-col>
        <v-btn x-large @click="saveChanges">Save Changes</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="addSectionHeading"
      max-width="600"
    >
      <v-card>
        <v-card-text class="pt-4">
          <label>
            Add a section heading for the BOQs
          </label>
          <v-text-field v-model="newSectionHeading"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="orange darken-2"
            text
            @click="saveSectionHeading"
          >
            Save
          </v-btn>
          <v-btn
            color="orange darken-2"
            text
            @click="addSectionHeading = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showChangeJobNo"
      max-width="600"
    >
      <v-card>
        <v-card-text class="pt-4">
          <label v-if="(jobNumbers.length === 0)">
            Enter the job number you wish to change this DRS to below:
          </label>
          <span v-if="(confirmNumber === false)">
            <v-checkbox
              v-for="(number, index) in jobNumbers"
              :key="index"
              :label="number"
              :ripple="false"
              @change="confirmJobNumber(index)"></v-checkbox>
          </span>
          <span v-else>
            Are you sure you want to change the job number on this DRS to: {{ selectedJobNumber }}<br />
            <v-btn @click="updateJobNumber" small>Confirm</v-btn>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-text-field
            v-if="(jobNumbers.length === 0)"
            placeholder="Job number"
            v-model="jobNumberTerm"></v-text-field>
          <v-btn
            v-if="(jobNumbers.length === 0)"
            color="orange darken-2"
            text
            @click="findJobNumbers"
          >
            Search
          </v-btn>
          <v-btn
            color="orange darken-2"
            text
            @click="showChangeJobNo = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMessage"
      max-width="600"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editSectionHeadings"
      max-width="600"
    >
      <v-card>
        <v-card-text class="pt-4">
          <div v-if="sectionHeadingInEdit === 0">
            <p v-for="(section, index) in sectionList" :key="`sl_${index}`">
              {{ section.description }}
              <v-btn x-small @click="sectionHeadingInEdit = section">
                Edit
              </v-btn>
            </p>
          </div>
          <div v-else>
            <v-text-field v-model="sectionHeadingInEdit.description"></v-text-field>
            <v-btn x-small @click="updateSectionHeading">Save</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'DrsUpdate',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    date () {
      this.drs.work_date = this.formatDate(this.date);
    },
    newSpecialBonus: {
      handler() {
        this.newSpecialBonus.total = (this.newSpecialBonus.quantity * this.newSpecialBonus.amount).toFixed(2);
      },
      deep: true,
    },
    totalBonus(value) {
      this.individualBonus = (value / this.drs.number_in_gang);
    },
    drs: {
      handler() {
        let totalValue = 0;
        for (let i = 0; this.drs.daily_return_sheets_bonus_payments.length > i; i += 1) {
          const lineValue = (
            this.drs.daily_return_sheets_bonus_payments[i].quantity * 
            this.drs.daily_return_sheets_bonus_payments[i].rate
          );
          totalValue += lineValue;
        }
        for (let x = 0; this.drs.daily_return_sheet_special_bonnuses.length > x; x += 1) {
          const lineValue = (
            this.drs.daily_return_sheet_special_bonnuses[x].quantity * 
            this.drs.daily_return_sheet_special_bonnuses[x].bonus_rate
          );
          totalValue += lineValue;
        }
        this.totalBonus = totalValue.toFixed(2);
      },
      deep: true,
    },
  },
  data() {
    return {
      addSectionHeading: false,
      drs: {
        daily_return_sheets_workers: [
          {
            crew_member: {},
          },
        ],
        daily_return_sheets_images: [],
        quotation: {},
        daily_return_sheets_bonus_payments: [],
        drs_misc_boq_1_description: '',
        drs_misc_boq_2_description: '',
        drs_misc_boq_3_description: '',
      },
      bonuses: [],
      showChangeJobNo: false,
      jobNumberTerm: '',
      jobNumbers: [],
      newBonus: {
        description: '',
        rate: 0,
        quantity: 0,
      },
      newSpecialBonus: {
        description: '',
        quantity: 0,
        amount: 0,
        total: '0.00',
      },
      selectedJobNumber: '',
      confirmNumber: false,
      sectionHeadingInEdit: 0,
      surfaceConditions: [],
      surfaceTypes: [],
      weatherConditions: [],
      workTypes: [],
      jobTypes: [],
      departments: [
        { 'value':1, 'text':'Paint'},
        { 'value':2, 'text':'Thermo'},
      ],
      crewMembers: [],
      times: [],
      menu: false,
      date: null,
      totalBonus: 0,
      individualBonus: 0,
      boolean: [
        { text: "No", value: 1 },
        { text: "Yes", value: 0 },
      ],
      newMaterial: {},
      message: '',
      showMessage: false,
      newSectionHeading: '',
      sectionHeadingId: 0,
      sectionList: [],
      editSectionHeadings: false,
    };
  },
  methods: {
    updateBoqSectionHeading(event) {
      const boqIndex = event.target.getAttribute("data-boq");
      const headingId = this.sectionList[event.target.options.selectedIndex].id;
      this.drs.daily_return_sheets_bonus_payments[boqIndex].section_id = headingId;
      const postData = {};
      postData.paymentId = this.drs.daily_return_sheets_bonus_payments[boqIndex].id;
      postData.sectionId = this.sectionList[event.target.options.selectedIndex].id;
      axios.post(`/dailyReturnSheetsBonusPayments/updateSection.json?token=${this.token}`, postData)
        .then(() => {
          console.log('Saved');  
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addNewSectionHeading() {
      this.addSectionHeading = true;
      this.newSectionHeading = '';
      this.sectionHeadingId = 0;
    },
    updateSectionHeading() {
      const postData = {};
      postData.section = this.sectionHeadingInEdit;
      axios.post(`/sections/update.json?token=${this.token}`, postData)
        .then(() => {
          this.sectionHeadingInEdit = 0;
          this.editSectionHeadings = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveSectionHeading() {
      const postData = {};
      postData.sectionTitle = this.newSectionHeading;
      axios.post(`/sections/add.json?token=${this.token}`, postData)
        .then((response) => {
          this.sectionHeadingId = response.data.section.id;
          this.addSectionHeading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    getSurfaceConditions() {
      axios.get(`/surfaceConditions/getList.json?token=${this.token}`)
        .then((response) => {
          this.surfaceConditions = response.data.surfaceConditions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSurfaceTypes() {
      axios.get(`/surfaceTypes/getList.json?token=${this.token}`)
        .then((response) => {
          this.surfaceTypes = response.data.surfaceTypes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getWeatherConditions() {
      axios.get(`/weatherConditions/getList.json?token=${this.token}`)
        .then((response) => {
          this.weatherConditions = response.data.weatherConditions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getWorkTypes() {
      axios.get(`/workTypes/getList.json?token=${this.token}`)
        .then((response) => {
          this.workTypes = response.data.workTypes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMaterial() {
      const postData = {};
      postData.material = this.newMaterial;
      postData.drsId = this.drs.id;
      axios.post(`/dailyReturnsSheetsMaterialUsages/addNew.json?token=${this.token}`, postData)
        .then(() => {
          this.getDrs();
          this.newMaterial = {};
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveChanges() {
      const postData = {};
      postData.drs = this.drs;
      postData.drs.sectionId = this.sectionHeadingId;
      postData.totalBonus = this.totalBonus;
      axios.post(`/dailyReturnSheets/update.json?token=${this.token}`, postData)
        .then(() => {
          this.$router.push(`/drs/view/${this.drs.id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveNewBonus() {
      const postData = {};
      postData.newBonus = this.newBonus;
      axios.post(`/bonusRates/createMiscBonusRate.json?token=${this.token}`, postData)
        .then((response) => {
          const postData = {};
          postData.bonus = response.data.bonusRate;
          postData.bonus.quantity = this.newBonus.quantity;
          postData.drs = this.drs;
          postData.sectionId = 0;
          axios.post(`/dailyReturnSheetsBonusPayments/addNew.json?token=${this.token}`, postData)
            .then(() => {
              this.getDrs();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSpecialBonus(index) {
      const sb = this.drs.daily_return_sheet_special_bonnuses[index];
      axios.post(`/dailyReturnSheetSpecialBonnuses/delete/${sb.id}.json?token=${this.token}`)
        .then(() => {
          this.drs.daily_return_sheet_special_bonnuses.splice(index, 1);
        });
    },
    saveNewSpecialBonus() {
      const postData = {};
      postData.newSpecialBonus = this.newSpecialBonus;
      postData.drsId = this.drs.id;
      axios.post(`/dailyReturnSheetSpecialBonnuses/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.drs.daily_return_sheet_special_bonnuses.push(response.data.specialBonus);
          this.newSpecialBonus = {
            description: '',
            quantity: 0,
            amount: 0,
            total: '0.00',
          };
        });
    },
    useBonus(index) {
      const bonus = this.bonuses[index];
      const postData = {};
      postData.bonus = bonus;
      postData.drs = this.drs;
      postData.sectionId = this.sectionHeadingId;
      axios.post(`/dailyReturnSheetsBonusPayments/addNew.json?token=${this.token}`, postData)
        .then(() => {
          this.getDrs();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addWorker() {
      const worker = { crew_member: { id: 0 } };
      this.drs.daily_return_sheets_workers.push(worker);
    },
    removeWorker(index) {
      const postData = {};
      postData.worker = this.drs.daily_return_sheets_workers[index];
      axios.post(`/dailyReturnSheetsWorkers/remove.json?token=${this.token}`, postData)
        .then(() => {
          this.drs.daily_return_sheets_workers.splice(index, 1);
          this.message = 'The worker has been removed.';
          this.showMessage = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateLine(index) {
      const boqToUpdate = this.drs.daily_return_sheets_bonus_payments[index];
      const postData = { boqToUpdate };
      axios.post(`/dailyReturnSheetsBonusPayments/Update.json?token=${this.token}`, postData)
        .then(() => {
          this.message = 'The update has been saved.';
          this.showMessage = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeLine(index) {
      const boqToRemove = this.drs.daily_return_sheets_bonus_payments[index];
      const postData = { boqToRemove };
      axios.post(`/dailyReturnSheetsBonusPayments/delete.json?token=${this.token}`, postData)
        .then(() => {
          this.drs.daily_return_sheets_bonus_payments.splice(index, 1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveMaterialChanges(index) {
      const materialToUpdate = this.drs.daily_returns_sheets_material_usages[index];
      const postData = { materialToUpdate };
      axios.post(`/dailyReturnsSheetsMaterialUsages/update.json?token=${this.token}`, postData);
    },
    removeMaterial(index) {
      const materialToRemove = this.drs.daily_returns_sheets_material_usages[index];
      const postData = { materialToRemove };
      axios.post(`/dailyReturnsSheetsMaterialUsages/delete.json?token=${this.token}`, postData)
        .then(() => {
          this.drs.daily_returns_sheets_material_usages.splice(index, 1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateJobNumber() {
      const postData = {};
      postData.newJobNumber = this.selectedJobNumber;
      postData.drs = this.drs.id;
      axios.post(`/dailyReturnSheets/updateJobNumber.json?token=${this.token}`, postData)
        .then((response) => {
          this.drs = response.data.dailyReturnSheet;
          this.showChangeJobNo = false;
          this.jobNumberTerm = '';
          this.jobNumbers = [];
          this.selectedJobNumber = '';
          this.confirmNumber = false;
          this.showMessage = true;
          this.message = 'The job number has been updated';
        })
        .catch((error) => {
          console.log(error);
        });
    },
    confirmJobNumber(index) {
      this.selectedJobNumber = this.jobNumbers[index];
      this.confirmNumber = true;
    },
    findJobNumbers() {
      const postData = {};
      postData.searchTerm = this.jobNumberTerm;
      axios.post(`/quotations/searchJobNumbers.json?token=${this.token}`, postData)
        .then((response) => {
          this.jobNumbers = response.data.jobNumbers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDrs() {
      const drsId = this.$route.params.id;
      axios.get(`/dailyReturnSheets/getSingle/${drsId}.json?token=${this.token}`)
        .then((response) => {
          this.drs = response.data.dailyReturnSheet;
          this.bonuses = response.data.bonuses;
          this.date = response.data.dailyReturnSheet.work_date;
          this.totalBonus = response.data.dailyReturnSheet.bonus_value;
          this.individualBonus = (
            response.data.dailyReturnSheet.bonus_value / 
            response.data.dailyReturnSheet.number_in_gang
          );
          this.buildSectionList();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buildSectionList() {
      const sectionList = [];
      for (let i = 0; i < this.drs.daily_return_sheets_bonus_payments.length; i += 1) {
        if (this.drs.daily_return_sheets_bonus_payments[i].section.id) {
          const section = {};
          section.id = this.drs.daily_return_sheets_bonus_payments[i].section.id;
          section.description = this.drs.daily_return_sheets_bonus_payments[i].section.description;
          sectionList.push(section);
        }
      }
      const sortedSections = sectionList.sort((a, b) => {
        return a.id - b.id;
      });

      this.sectionList = this.uniqueSections(sortedSections);
    },
    uniqueSections(sections) {
      let last = 0;
      const uniques = [];
      for (let i = 0; sections.length > i; i += 1) {
        if (sections[i].id !== last) {
          uniques.push(sections[i]);
        }
        last = sections[i].id;
      }
      return uniques;
    },
    generateTimes() {
      let i = 0; 
      const times = [];
      let time = 0.25;
      while(i < 71) {
        times.push(time);
        time += 0.25;
        i += 1;
      }
      this.times = times;
    },
    getCrewMembers() {
      axios.get(`/crewMembers/getCrewForDrsEdit.json?token=${this.token}`)
        .then((response) => {
          this.crewMembers = response.data.crewMembers;
        });
    },
    getJobTypes() {
      axios.get(`/jobTypes/getList.json?token=${this.token}`)
        .then((response) => {
          this.jobTypes = response.data.jobTypes;
        });
    },
  },
  mounted() {
    this.getDrs();
    this.getSurfaceConditions();
    this.getSurfaceTypes();
    this.getWeatherConditions();
    this.getWorkTypes();
    this.generateTimes();
    this.getCrewMembers();
    this.getJobTypes();
  },
};
</script>
